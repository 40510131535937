import { Link } from 'react-router-dom';

function Terms() {

    return (
        <div style={{backgroundColor:'white'}}>
        {/*Page Header Start*/}
<section className="page-header">
<div
  className="page-header__bg"
  style={{
    backgroundImage: "url(assets/images/backgrounds/slider-1-2.jpg)"
  }}
></div>
<div className="container">
  <div className="page-header__inner">
    <h2>Terms & Condition</h2>
    <div className="thm-breadcrumb__box">
      <ul className="thm-breadcrumb list-unstyled">
        <li>
          <a href="/">Home</a>
        </li>
        <li>
          <span className="icon-arrow-left" />
        </li>
        <li>Terms & Condition</li>
      </ul>
    </div>
  </div>
</div>
</section>

<div className="container" style={{paddingTop:'40px', paddingBottom:"30px"}} >
<div className="section-title text-center" style={{ marginBottom: 0 }}>
        <div className="section-title__tagline-box">
          <span className="section-title__tagline" style={{ color: "black" }}>
          Terms & Condition
          </span>
        </div>
        <br />
        <img
          alt=""
          loading="lazy"
          width={97}
          height={13}
          decoding="async"
          data-nimg={1}
          style={{ color: "transparent" }}
          src="assets/images/svg/separator.eb50640e.svg"
        />
      </div>
      <div className="">
        <p><b>Terms and Conditions:</b>
        </p>
        <p>
        Company maintains the <Link to="https://rizchauffeur.com">www.rizchauffeur.com</Link> Website.
        The following are the terms of use that govern use of the Site ("Terms of Use").
        By using the Site you expressly agree to be bound by these Terms of Use and the <Link to="https://rizchauffeur.com">www.rizchauffeur.com</Link> privacy policy and to follow these Terms of Use and all applicable laws and regulations governing use of the Site.
        </p>
        </div>
        <p style={{color:'black', paddingTop:'10px'}}>
        1. SITE OPERATION
          <p style={{paddingLeft:'20px'}}>United Arab Emirates is our Country of Domicile. Company controls this Site from the U.A.E. Company makes no representation that this Site is appropriate for use in other locations. If you use this Site from other locations you are responsible for ensuring compliance with local laws. You may not use, export or re-export any materials from this Site in violation of any applicable laws or regulations, including, but not limited to any U.A.E export laws and regulations.</p>
        </p>
        <p style={{color:'black', paddingTop:'10px'}}>
        2. APPLICABLE LAW
          <p style={{paddingLeft:'20px'}}>
          The Laws of the United Arab Emirates shall govern the use of the Site and the Terms of Use, without regards to conflict of laws principals. All disputes arising in connection therewith shall be heard only by a court of competent jurisdiction in U.A.E.
            </p>
        </p>
        <p style={{color:'black', paddingTop:'10px'}}>
        3. MULTI-CURRENCY PRICED TRANSACTION
          <p style={{paddingLeft:'20px'}}>
            The displayed price and currency selected by you, will be the same price and currency charged to the Card and printed on the Transaction Receipt.
            </p>
        </p>
        <p style={{color:'black', paddingTop:'10px'}}>
        4. PURCHASES
          <p style={{paddingLeft:'20px'}}>
            Company accepts payment by Visa or Mastercard debit and credit cards in AED for its products and services. All online purchases are also governed by the terms and conditions of respective merchant service providers. Please review respective merchant service provider’s user agreement and privacy policy before entering any transaction. Interpretation. These Terms of Use supersede all other written and oral communications or agreements with regard to the subject matters discussed in these Terms of Use. A waiver or modification of these Terms of Use will only be effective if made in writing signed by an authorized officer of Company. All refunds will be made onto the original mode of payment.
            </p>
        </p>
        <p style={{color:'black', paddingTop:'10px'}}>
        5. OFFICE FOREIGN ASSETS CONTROL (OFAC) SANCTIONED COUNTRIES
          <p style={{paddingLeft:'20px'}}>
            Company will not trade with or provide any services to individuals and companies owned or controlled by, or acting for or on behalf of, OFAC targeted countries AND individuals, groups, and entities, such as terrorists and narcotics traffickers designated under the OFAC programs that are not country specific.
            </p>
        </p>
        <p style={{color:'black', paddingTop:'10px'}}>
        6. REPRESENTATIONS BY YOU
          <p style={{paddingLeft:'20px'}}>
            By visiting the Site, you represent, warrant and covenant that (a) you are at least 18 years old; (b) that all materials of any kind submitted by you to Company through the Site or for inclusion on the Site will not plagiarize, violate or infringe upon the rights of any third-party including trade secret, copyright, trademark, trade dress, privacy, patent, or other personal or proprietary rights. The customer using the website who are Minor /under the age of 18 shall not register as a User of the website and shall not transact on or use the website.
            </p>
        </p>
        <p style={{color:'black', paddingTop:'10px'}}>
        7. PERMITTED USE
          <p style={{paddingLeft:'20px'}}>
          You agree that you are only authorized to visit, view and to retain a copy of pages of this Site for your own personal use, that you shall not duplicate, download, publish, modify or otherwise distribute the material on this Site for any purpose other than for personal use, unless otherwise specifically authorized by Company to do so. You also agree not to deep-link to the site for any purpose, unless specifically authorized by Company to do so. The content and software on this Site is the property of Company. The cardholder must retain a copy of transaction records and Merchant policies and rules.
            </p>
        </p>
        <p style={{color:'black', paddingTop:'10px'}}>
        8. YOUR ACCOUNT
          <p style={{paddingLeft:'20px'}}>
            If you use Company Site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your account from any devices, and you agree to accept responsibility for all activities that occur under your account or password. The Site shall not be responsible or liable, directly or indirectly, in any way for any loss or damage of any kind incurred as a result of, or in connection with, your failure to comply with this section.
            </p>
        </p>
        <p style={{paddingTop:'20px'}}>
        NO COMMERCIAL USE. This Site may not be used by you for any commercial purposes such as to conduct sales of merchandise or services of any kind. You must obtain Company’s prior written consent to make commercial offers of any kind on the Site, whether by advertising, solicitations, links, or any other form of communication. Company will investigate and take appropriate legal action against anyone who violates this provision, including without limitation, removing the offending communication from the Site and barring such violators from use of the Site.
        </p>
        <p style={{paddingTop:'20px'}}>
        LINKS AND SEARCH RESULTS. The Site may automatically produce search results that reference and/or link to third party sites throughout the World Wide Web. Company has no control over these sites or the content within them. Company does not guarantee, represent or warrant that the content contained in the sites is accurate, legal and/or inoffensive. Company does not endorse the content of any third party site, nor does it make any representation or warranty about these sites, including that they will not contain viruses or otherwise impact your computer. By using the Site to search for or link to another site, you agree and understand that you may not make any claim against Company for any damages or losses, whatsoever, resulting from your use of the Site to obtain search results or to link to another site. If you have a problem with a link from the Site, you may notify us at booking@rizchauffeur.com.
        </p>
        <p style={{paddingTop:'20px'}}>
        COPYRIGHT POLICY. Company may terminate the privileges of any user who uses this Site to unlawfully transmit copyrighted material without a license, express consent, valid defense or fair use exemption to do so. If you submit information to this Site, you warrant that the information does not infringe the copyrights or other rights of third parties.
        </p>
        <p style={{paddingTop:'20px'}}>
        INTELLECTUAL PROPERTY. Although Company is not responsible for the content, quality or accuracy of data provided by users, compilations of such data, text, information and other materials made available to users through Company’s system. The On-line Materials are Company’s intellectual property, and are protected by U.S. and international intellectual property laws. The On-line Materials may not be copied or redistributed either in whole or in part without prior written consent of Company, except as expressly and specifically permitted under these Terms of Use.
        </p>
        <p style={{paddingTop:'20px'}}>
        VIOLATION OF TERMS OF USE. You understand and agree that in Company’s sole discretion, and without prior notice, Company may terminate your access to the Site, or exercise any other remedy available and remove any unauthorized user information, if Company believes that the information you provide has violated or is inconsistent with these Terms of Use, or violates the rights of Company, or any third party, or violates the law. You agree that monetary damages may not provide a sufficient remedy to Company for violations of these Terms of Use and you consent to injunctive or other equitable relief for such violations. Company may release user information about you if required by law or subpoena.
          </p>
          <p style={{paddingTop:'20px'}}>
          INDEMNITY. You agree to indemnify and hold Company, its subsidiaries, affiliates, officers, agents and other partners and employees, harmless from any loss, liability, claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of or relating to your use of the Site, including also your use of the Site to provide a link to another site or to upload content or other information to the Site, or your breach of the Terms of Use.
            </p>
            <p style={{paddingTop:'20px'}}>
            ADVERTISING. The Site may contain advertisements and/or sponsorships. The advertisers and/or sponsors that provide these advertisements and sponsorships are solely responsible for insuring that the materials submitted for inclusion on the Site are accurate and comply with all applicable laws. Company is not responsible for the acts or omissions of any advertiser or sponsor.
            </p>
            <p style={{paddingTop:'20px'}}>
            SEVERABILITY. If any provision of the Terms of Use is held to be invalid, void or unenforceable, the remaining provisions shall nevertheless continue in full force and effect.
              </p>
              <p style={{paddingTop:'20px'}}>
              Headings & section titles in this Agreement are for convenience and do not define, limit, or extend any provision of this Agreement.
              </p>
</div>

 {/* /.page-wrapper */}
 <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="/" style={{ paddingLeft: "0PX" }}>
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginLeft: "-40px" }}
            alt=""
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@packageName__.com">
            booking@rizchauffeur.com
          </a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:666-888-0000">+971 563847412</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" className="fab fa-twitter" />
          <a href="#" className="fab fa-facebook-square" />
          <a href="#" className="fab fa-pinterest-p" />
          <a href="#" className="fab fa-instagram" />
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
</div>
    )
};
export default Terms;