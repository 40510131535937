import { Link } from "react-router-dom";

function Services() {
    return (
        <div className="page-wrapper">
            {/*Page Header Start*/}
  <section className="page-header">
    <div
      className="page-header__bg"
      style={{
        backgroundImage: "url(assets/images/project/bmw1.png)"
      }}
    ></div>
    <div className="container">
      <div className="page-header__inner">
        <h2>Services</h2>
        <div className="thm-breadcrumb__box">
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <Link href="/">Home</Link>
            </li>
            <li>
              <span className="icon-arrow-left" />
            </li>
            <li>Services</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  {/*Page Header End*/}


 {/*Service One Start */}
 <section className="service-one">
    <div className="container">
      <div className="row">
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInLeft"
          data-wow-delay="100ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              {/*<span class="icon-car"></span>*/}
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/airporttransfer">Airport transfer</Link>
            </h3>
            <p className="service-one__text">
              When you are travelling to somewhere, it will be thrilling and
              exciting to enjoy your at its best and to reduce stress.{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/airporttransfer">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
          data-wow-delay="200ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/busrental">
                Bus | Minibus | Van rental
              </Link>
            </h3>
            <p className="service-one__text">
              Experience the epitome of luxury while exploring the vibrant city
              of Dubai with our exclusive luxury bus rental services.
            </p>
            <div className="service-one__read-more">
              <Link to="/busrental">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="300ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/chauffeurservices">Chauffeur Service</Link>
            </h3>
            <p className="service-one__text">
              The politeness and dedication along with the smart driving skills
              of the experienced chauffeurs ensure a safe and luxurious service.
            </p>
            <div className="service-one__read-more">
              <Link to="/chauffeurservices">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInLeft"
          data-wow-delay="400ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/limousineservice">Limousine Service Dubai</Link>
            </h3>
            <p className="service-one__text">
              There you will have a comfortable journey and can join a
              discussion online or arrange it even inside the limousine.
            </p>
            <div className="service-one__read-more">
              <Link to="/limousineservice">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
          data-wow-delay="500ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/hourlyrateservice">Hourly Rate Service</Link>
            </h3>
            <p className="service-one__text">
              We provide a luxurious fleet of cars and vans that enable us to
              transport in the best way with professional chauffeurs.{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/hourlyrateservice">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/groundtransportation">Ground Transportation</Link>
            </h3>
            <p className="service-one__text">
              Customer satisfaction is crucial for amohlodi business as it leads
              to customer loyalty loves positive word-of-mouth, repeat.{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/groundtransportation">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
         {/*Services One Single Start*/}
         <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/eventtransportation">Event Transportation</Link>
            </h3>
            <p className="service-one__text">
            When it comes to event transportation, many might not recognize how crucial a proper logistics and transportation management system...{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/eventtransportation">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
         {/*Services One Single Start*/}
         <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/corporatetransportation">Corporate Transportation</Link>
            </h3>
            <p className="service-one__text">
            Our corporate transport service in Dubai is presenting you with great transport services at affordable rates...{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/corporatetransportation">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        {/*Services One Single Start*/}
        <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/citytour">City Tour</Link>
            </h3>
            <p className="service-one__text">
            Discover Dubai as you have never seen before. You can make the most of this city simply by exploring some of the finest places....{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/citytour">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
      
       {/*Services One Single Start*/}
       <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/porttransfer">Port Transfer</Link>
            </h3>
            <p className="service-one__text">
            Riz ensure worry free and stylish transportation within and beyond the city, by properly addressing your concerns in terms of safety...{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/porttransfer">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
        
          {/*Services One Single Start*/}
       <div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/intercitytransfer">Intercity Transfer</Link>
            </h3>
            <p className="service-one__text">
            Take all the hassles out of traveling from one point to another in the UAE with this premium Dubai Intercity Transfers...{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/intercitytransfer">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}

{/*Services One Single Start*/}
<div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/desertsafari">Desert Safari</Link>
            </h3>
            <p className="service-one__text">
            Advanced Dubai desert safari tour package includes dune bashing, camel riding, sand boarding, buffet dinner, and pick-up from...{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/desertsafari">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}

        {/*Services One Single Start*/}
<div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/stafftransportation">Staff Transportation</Link>
            </h3>
            <p className="service-one__text">
            We offer transportation services in all the emirates to several reputable companies through our dedicated shuttle service...{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/stafftransportation">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}

         {/*Services One Single Start*/}
<div
          className="col-xl-4 col-lg-6 col-md-6 wow fadeInRight"
          data-wow-delay="600ms"
        >
          <div className="service-one__single">
            <div className="service-one__icon">
              <i
                className="fas fa-car"
                style={{ fontSize: 48, color: "black" }}
              />
            </div>
            <h3 className="service-one__title">
              <Link to="/limoridedubai">Limo Ride Dubai</Link>
            </h3>
            <p className="service-one__text">
            Best Limousine Ride in Dubai — Stretch Limousine from Skye VIP Limousine offers a luxurious and memorable experience...{" "}
            </p>
            <div className="service-one__read-more">
              <Link to="/limoridedubai">
                Read More
                <span className="icon-arrow-left" />
              </Link>
            </div>
          </div>
        </div>
        {/*Services One Single End*/}
</div>
    </div>
  </section>
  {/*Service One End */}

  {/* /.page-wrapper */}
 <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="/" style={{ paddingLeft: "0PX" }}>
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginLeft: "-40px" }}
            alt=""
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@packageName__.com">
            booking@rizchauffeur.com
          </a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:666-888-0000">+971 563847412</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" className="fab fa-twitter" />
          <a href="#" className="fab fa-facebook-square" />
          <a href="#" className="fab fa-pinterest-p" />
          <a href="#" className="fab fa-instagram" />
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
        </div>
        
    )
};
export default Services;