
function OurFleet() {
    return (
        
        <div style={{backgroundColor:'white'}}>
            {/*Page Header Start*/}
  <section className="page-header">
    <div
      className="page-header__bg"
      style={{
        backgroundImage: "url(assets/images/project/bmw1.png)"
      }}
    ></div>
    <div className="container">
      <div className="page-header__inner">
        <h2>Our Fleet</h2>
        <div className="thm-breadcrumb__box">
          <ul className="thm-breadcrumb list-unstyled">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <span className="icon-arrow-left" />
            </li>
            <li>Our Fleet</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
<div className="container" style={{paddingTop:'40px'}}>
      <div className="section-title text-center" style={{ marginBottom: 0 }}>
        <div className="section-title__tagline-box">
          <span className="section-title__tagline" style={{ color: "black" }}>
            Our Fleets
          </span>
        </div>
        <br />
        <img
          alt=""
          loading="lazy"
          width={97}
          height={13}
          decoding="async"
          data-nimg={1}
          style={{ color: "transparent" }}
          src="assets/images/svg/separator.eb50640e.svg"
        />
      </div>
      <div className="pageContent">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}

                  src="assets/images/fleet/Rolls20Phantom-new.jpg"
                style={{ color:'transparent'}}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }} className="alGallery">
                  Rolls Royce Phantom
                </div>
                <div className="al_brandName" style={{color:'goldenrod', fontWeight:'bold'}}>Rolls-Royce</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/Rolls20Ghost-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }} >Rolls Royce Ghost</div>
                <div style={{color:'goldenrod', fontWeight:'bold'}} className="al_brandName">Rolls-Royce</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/rolls20cullinan-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Rolls-Royce Cullinan</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}}className="al_brandName">Rolls-Royce</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/bentley-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Bentley Flying Spur </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">BENTLEY</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href="/our-fleets/bentley-flying-spur--61"
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  decoding="async"
                  data-nimg={1}
                  src="assets/images/fleet/mercedes20class-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div  style={{ padding: "5px 0px", color:'white' }}>Mercedes S Class </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span  style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href="/our-fleets/mercedes-s-class--79"
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/bmw207-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>BMW 7 series</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">BMW</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'
                  >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'
                  >max. 2 Baggage</span>
                </div>
                <p className='text-white'
                >Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/audi-a8-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Audi A8 </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">AUDI</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/Cadillac20Escalade-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Cadillac Escalade</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">Cadillac Escalade</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>6 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 5 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/gmc20yukon-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>GMC Yukon</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">GMC</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>6 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 5 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/mercedesVclass-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Mercedes V class </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>7 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 5 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/HondaOdyssey-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }} className='text-white'>Honda Odyssey</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">HONDA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>4 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 5 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          
          <div className="col-lg-4 col-md-6">
          <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/ToyotaPrevia-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Toyota Previa</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>4 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 5 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/ToyotaGranvia-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Toyota Granvia</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>4 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 3 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/luxus-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Lexus ES 350</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">LEXUS</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/mercedesEclass-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Mercedes E Class</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/bmw5-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>BMW 5 Series</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">BMW</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets"  style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/AudiA6-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Audi A6</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">AUDI</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 2 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/mercedessprinter-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Mercedes Sprinter</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>16 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 12 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/toyotahiace-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Toyota Hiace</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>11 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 7 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}
            >
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/toyotacoaster-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Toyota Coaster </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>21 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 16 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/3520luxurybus-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>35 Seater Luxury Bus</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>35 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 30 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/5020luxurybus-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>50 Seater Luxury Bus</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TOYOTA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'
                  >50 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'
                  >max. 45 Baggage</span>
                </div>
                <p className='text-white'
                >Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/Tesla203-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Tesla Model 3 </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TESLA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'
                  >3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'
                  >max. 2 Baggage</span>
                </div>
                <p className='text-white'
                >Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/Tesla20Y-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Tesla Model Y</div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">TESLA</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>3 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 3 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="fleets" style={{border:'1px solid goldenrod', backgroundColor:'#000'}}>
              <div className="fleetImg">
                <img
                  alt=""
                  loading="lazy"
                  width={800}
                  height={531}
                  src="assets/images/fleet/limo-new.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              <div className="fleetTxt">
                <div style={{ padding: "5px 0px", color:'white' }}>Strech Limousine </div>
                <div style={{color:'goldenrod',fontWeight:'bold'}} className="al_brandName">MERCEDES</div>
                <div>
                  <span style={{paddingRight:'20px'}} className='text-white'>16 Seats</span>
                  <span className="vl" />
                  <span style={{paddingLeft:'20px'}} className='text-white'>max. 0 Baggage</span>
                </div>
                <p className='text-white'>Luxury Class</p>
                <div className="al_viewMoreBtn">
                  <a
                    className="al_readmore "
                    href=""
                  >
                    <span className="lte-btn-before" />
                    View Details
                    <span className="lte-btn-after" />
                  </a>
                  <a className="al_readmore " href="">
                    <span className="lte-btn-before" />
                    Book Now
                    <span className="lte-btn-after" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* /.page-wrapper */}
 <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a href="/" style={{ paddingLeft: "0PX" }}>
          <img
            src="assets/images/resources/logosmall.png"
            height="80px"
            style={{ marginLeft: "-40px" }}
            alt=""
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
          <i className="fa fa-envelope" />
          <a href="mailto:needhelp@packageName__.com">
            booking@rizchauffeur.com
          </a>
        </li>
        <li>
          <i className="fa fa-phone-alt" />
          <a href="tel:666-888-0000">+971 563847412</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__top">
        <div className="mobile-nav__social">
          <a href="#" className="fab fa-twitter" />
          <a href="#" className="fab fa-facebook-square" />
          <a href="#" className="fab fa-pinterest-p" />
          <a href="#" className="fab fa-instagram" />
        </div>
        {/* /.mobile-nav__social */}
      </div>
      {/* /.mobile-nav__top */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  
  <div className="scroll-to-top">
    <svg className="scroll-top-inner" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
        </div>
    )
};
export default OurFleet;